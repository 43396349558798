import React, {useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import Sidebar from "./Sidebar/Sidebar" 
import MainPage from "./main page/MainPage"
import {
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles"

const drawerWidth = 290;

let theme = createMuiTheme({
  palette: {
    primary: {
      // blue
      main: "rgb(2, 77, 107)",
      light: "rgb(55, 106, 140)",
      dark: "rgb(37 ,64, 83)"
    },
    secondary: {
      // yellow
      main: "rgb(243, 155, 67)",
      light: "rgb(203, 177, 111)",
      dark: "rgb(197, 131, 55)"
    },
    error: {
      // red
      main: "rgb(198, 53, 65)",
      light: "rgb(206, 97, 94)",
      dark: "rgb(159, 57, 59)"
    }
  },
  
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: null
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgb(2, 77, 107)",
          color: "white"
        },
        "&$selected:hover": {
          backgroundColor: "rgb(2, 77, 107)",
          color: "white"
        }
      }
    }
  }
})

function App() {
  const [files, setFiles] = useState([])
  const [selected, setSelected] = useState([])
  const [processing, setProcessing] = useState(false)
  return (
    <MuiThemeProvider theme={theme}>
       <div>
      <CssBaseline />
      <Sidebar drawerWidth={drawerWidth} files={files} setFiles={setFiles} selected={selected} setSelected={setSelected} setProcessing={setProcessing}>
        <MainPage  drawerWidth={drawerWidth} files={files} selected={selected} processing={processing}/>
      </Sidebar>
 
    </div>

    </MuiThemeProvider>
   
  );
}

export default App;
