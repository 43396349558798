export const vatRates =   {
        "AT": {
            "country": "Austria",
            "standard_rate": 0.20,
            "reduced_rate": 10.00,
            "reduced_rate_alt": 13.00,
            "super_reduced_rate": false,
            "parking_rate": 12.00
        },
        "BE": {
            "country": "Belgium",
            "standard_rate": 0.21,
            "reduced_rate": 12.00,
            "reduced_rate_alt": 6.00,
            "super_reduced_rate": false,
            "parking_rate": 12.00
        },
        "BG": {
            "country": "Bulgaria",
            "standard_rate": 0.20,
            "reduced_rate": 9.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "CY": {
            "country": "Cyprus",
            "standard_rate": 0.19,
            "reduced_rate": 9.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "CZ": {
            "country": "Czech Republic",
            "standard_rate": 0.21,
            "reduced_rate": 15.00,
            "reduced_rate_alt": 10.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "DK": {
            "country": "Denmark",
            "standard_rate": 0.25,
            "reduced_rate": false,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "DE": {
            "country": "Germany",
            "standard_rate": 0.19,
            "reduced_rate": 7.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "EE": {
            "country": "Estonia",
            "standard_rate": 0.20,
            "reduced_rate": 9.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "EL": {
            "_comment": "While the EU uses the country code 'EL' for Greece, ISO uses 'GR' - both are included for convenience.",
            "iso_duplicate": "GR",
            "country": "Greece",
            "standard_rate": 0.24,
            "reduced_rate": 13.00,
            "reduced_rate_alt": 6.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "GR": {
            "_comment": "Duplicate of EL for convenience; the EU uses the country code 'EL' for Greece, while ISO uses 'GR'.",
            "iso_duplicate_of": "EL",
            "country": "Greece",
            "standard_rate": 0.24,
            "reduced_rate": 13.00,
            "reduced_rate_alt": 6.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "ES": {
            "country": "Spain",
            "standard_rate": 0.21,
            "reduced_rate": 10.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": 4.00,
            "parking_rate": false
        },
        "FI": {
            "country": "Finland",
            "standard_rate": 0.24,
            "reduced_rate": 14.00,
            "reduced_rate_alt": 10.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "FR": {
            "country": "France",
            "standard_rate": 0.20,
            "reduced_rate": 10.00,
            "reduced_rate_alt": 5.50,
            "super_reduced_rate": 2.10,
            "parking_rate": false
        },
        "HR": {
            "country": "Croatia",
            "standard_rate": 0.25,
            "reduced_rate": 13.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "IT": {
            "country": "Italy",
            "standard_rate": 0.22,
            "reduced_rate": 10.00,
            "reduced_rate_alt": 4.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "LV": {
            "country": "Latvia",
            "standard_rate": 0.21,
            "reduced_rate": 12.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "LT": {
            "country": "Lithuania",
            "standard_rate": 0.21,
            "reduced_rate": 9.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "LU": {
            "country": "Luxembourg",
            "standard_rate": 0.17,
            "reduced_rate": 14.00,
            "reduced_rate_alt": 8.00,
            "super_reduced_rate": 3.00,
            "parking_rate": 12.00
        },
        "HU": {
            "country": "Hungary",
            "standard_rate": 0.27,
            "reduced_rate": 18.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "IE": {
            "country": "Ireland",
            "standard_rate": 0.23,
            "reduced_rate": 13.50,
            "reduced_rate_alt": 9.00,
            "super_reduced_rate": 4.80,
            "parking_rate": 13.50
        },
        "MT": {
            "country": "Malta",
            "standard_rate": 0.18,
            "reduced_rate": 7.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "NL": {
            "country": "Netherlands",
            "standard_rate": 0.21,
            "reduced_rate": 6.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "PL": {
            "country": "Poland",
            "standard_rate": 0.23,
            "reduced_rate": 8.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "PT": {
            "country": "Portugal",
            "standard_rate": 0.23,
            "reduced_rate": 13.00,
            "reduced_rate_alt": 6.00,
            "super_reduced_rate": false,
            "parking_rate": 13.00
        },
        "RO": {
            "country": "Romania",
            "standard_rate": 0.19,
            "reduced_rate": 9.00,
            "reduced_rate_alt": 5.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "SI": {
            "country": "Slovenia",
            "standard_rate": 0.22,
            "reduced_rate": 9.50,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "SK": {
            "country": "Slovakia",
            "standard_rate": 0.20,
            "reduced_rate": 10.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "SE": {
            "country": "Sweden",
            "standard_rate": 0.25,
            "reduced_rate": 12.00,
            "reduced_rate_alt": 6.00,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "XI": {
            "_comment": "While the EU uses the country code 'UK' for the United Kingdom, ISO uses 'GB' - both are included for convenience.",
            "iso_duplicate": "GB",
            "country": "United Kingdom",
            "standard_rate": 0.20,
            "reduced_rate": 5.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        },
        "NI": {
            "_comment": "While the EU uses the country code 'UK' for the United Kingdom, ISO uses 'GB' - both are included for convenience.",
            "iso_duplicate": "GB",
            "country": "United Kingdom",
            "standard_rate": 0.20,
            "reduced_rate": 5.00,
            "reduced_rate_alt": false,
            "super_reduced_rate": false,
            "parking_rate": false
        }
        
}