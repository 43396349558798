import React, {useState, useEffect} from 'react';
import Topbar from "./Topbar"
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import DisplayData from "./DisplayData";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = drawerWidth => makeStyles(theme => ({
    root: {
      marginLeft: drawerWidth + 20,
      marginRight: "2%"
    },
  }));
  
function TabPanel(props){
  const {children, value, index, processing} = props

  return (
    <Container
      maxWidth={false}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index  && children}
    </Container>
  )
}

export default function MainPage(props){
    const {drawerWidth, files, selected, processing} = props

    const [currentTab, setCurrentTab] = useState(0)
    const [selectedFiles, setSelectedFiles] = useState([])

    useEffect(() => {
      const chosenFiles = []
      for(const [i, file] of files.entries()){
        if(selected[i]){
          chosenFiles.push(file)
        }
      }
      setSelectedFiles(chosenFiles)
    }, [selected, files])

    useEffect(() => {
      //console.log(selectedFiles)
    }, [selectedFiles])

    const handleTabChange = (e, index) => {
      setCurrentTab(index)
    }

    const classes = useStyles(drawerWidth)();
    return (
            <div className={`${classes.root} printme`}>
                <Topbar>

                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      centered
                      indicatorColor="primary"
                    >
                      {selectedFiles.map((file,i) =>{
                        
                         return <Tab key={i} label={file.fileData.name} />
                      })}
                    </Tabs>
                    {processing && <LinearProgress color="primary" />}
                    {!processing && selectedFiles.map((file, i) => {
                        return <TabPanel
                          key={i}
                          index={i}
                          value={currentTab}
                        >
                          <DisplayData index={i} data={file.data}/>
                        </TabPanel>
                      
                    })}
                    
                </Topbar>
                

            </div>
             
        
        
           
        
        
    )
}