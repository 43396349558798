import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles(theme => ({
    root:{
        padding: theme.spacing(5, 5),
        display: "flex",
        flex: "1 1 auto",
        alignItems: "center",
        paddingBottom: theme.spacing(3),
        justifyContent: "space-between"

    },
    paper:{
        height: "auto",
        minHeight: "500px"
    }
}))

export default function TopBar(props){
    const {children} = props
    const classes = useStyles()

    return(
        
            <Paper classes={{root: classes.paper}}>
                <div className={classes.root}>
                    <Typography component="h1" variant="h4">IOSS Checker</Typography>
                    <Button variant="contained" color="primary" onClick={()=> window.print() }>Save PDF</Button>
                </div>
                <Divider />
                {children}
            </Paper>
            
    )
}