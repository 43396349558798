import {vatRates} from "./vatRates"
export async function performCalculation(file){
    try{

        const allowedHeaders = ["TRANSACTION_ID", "CLIENT_ID", "MARKETPLACE", "SALES_CHANNEL", "TRANSACTION_TYPE", "GROSS_AMOUNT", "NET_VALUE_OF_GOODS", "CURRENCY_CODE", "ITEM_NAME", "SKU", "DEPARTURE_COUNTRY_CODE", "ARRIVAL_COUNTRY_CODE", "STOCK_MOVEMENT_QUANTITY", "BUYER_VAT_NUMBER", "BUYER_NAME", "BUYER_ADDRESS_1", "BUYER_ADDRESS_2", "BUYER_ADDRESS_3", "BUYER_POSTCODE", "ORDER_ID", "INVOICE_ID", "PAYMENT_DATE", "INVOICE_DATE", "DISPATCH_DATE", "PREP_DATE", "DRC_FISCAL_REP_APPROVED", "DRC_ESTABLISHED_APPROVED", "IMPORTER_OF_RECORD", "CONSIGNMENT_ID", "IS_VAT_COLLECTED_BY_MARKETPLACE", "_ATTENTION_REQUIRED", "_HISTORY", "_RESOURCE_TYPE", "_SRC_FILE"]
        const NewallowedHeaders = ["TRANSACTION_ID", "PAYMENT_DATE", "INVOICE_DATE", "GROSS_AMOUNT", "NET_VALUE_OF_GOODS", "CURRENCY_CODE", "DEPARTURE_COUNTRY_CODE", "ARRIVAL_COUNTRY_CODE"]
        const text = await file.text()
        const allLines = text.split(/\r\n|\n/)
        const headers = allLines[0].split(",")
        for(const header of headers){
            if(!allowedHeaders.includes(header) && header !== ""){
                throw `Invalid header found: ${header}! is the data in the right template?`
            }
        }
        const output = {
            totalMonths: 0,
            avg: {
                gross: 0,
                net: 0,
                vat: 0
            },
            monthData:{}
        }
        const paydateCol = headers.indexOf("PAYMENT_DATE")
        const invdateCol = headers.indexOf("INVOICE_DATE")
        const grossCol = headers.indexOf("GROSS_AMOUNT")
        const netCol = headers.indexOf("NET_VALUE_OF_GOODS")
        const countryCol = headers.indexOf("ARRIVAL_COUNTRY_CODE")
        const currencyCol = headers.indexOf("CURRENCY_CODE")
        for(const [i,line] of allLines.entries()){
            if(i > 0 && line !== ""){
                const values = line.split(",")

                let netVal = parseFloat(values[netCol].trim())
                let grossVal = parseFloat(values[grossCol].trim())
                const currency = values[currencyCol].trim()

                
                if(isNaN(netVal) || netVal === "") {throw `Error: Net of '${values[netCol]}' is not a valid number on line ${i +1}`}

                if(netVal <= 150){
                    const paymentDate = values[paydateCol].trim()
                    const invoiceDate = values[invdateCol].trim()
                    console.log(i+1)
                    console.log(values)

                    const transDate = getTransactionDate(i, paymentDate, invoiceDate)
                    const year = transDate.getFullYear()
                    const month = transDate.getMonth()
                    //add month/year to data if it is first time found
                    if(!output.monthData[year]){ 
                        output.monthData[year] = {}
                    }

                    if(!output.monthData[year][month]){
                        output.monthData[year][month] = {
                            gross: 0,
                            net: 0,
                            vat: 0
                        }
                        output.totalMonths += 1
                    }
                    const dataMonth = output.monthData[year][month]

                    if(currency !== "EUR"){
                        if(!currency){throw `Error: Currency cannot be blank on line ${i +1}`}

                        if(!output.monthData[year][month][currency]){
                            let mm = transDate.getMonth()+1
                            mm = mm>9 ? mm : "0"+mm
    
                            let dd = transDate.getDate()
                            dd = dd>9 ? dd : "0"+dd
                            const dateStr = transDate.getFullYear() + "-" + mm + "-" + dd
    
                            //console.log(dateStr)
                            const fxrateRes = await fetch(
                                "https://europe-west2-svat-api-development.cloudfunctions.net/macroApi",
                                {
                                   method: "Post",
                                    headers: {
                                        "Content-Type": "application/json",
                                      },
                                    body:JSON.stringify({
                                        "type": "fxrate",
                                        "options": {
                                            "fromCurrency": currency,
                                            "toCountry": "DE",
                                            "date": dateStr
                                         }
                                    })
                                }
                            )
    
                            if(fxrateRes.status === 200){
                                const fxRate = await fxrateRes.json()
                                grossVal = grossVal * fxRate
                                netVal = netVal * fxRate
                                output.monthData[year][month][currency] = fxRate

                            }else{
                                throw `Error while converting currency to EUR on line ${i+1} check currency code ${currency} and the date ${dateStr} are correct.`
                            }
                        }else{
                            const fxRate = output.monthData[year][month][currency]
                            grossVal = grossVal * fxRate
                            netVal = netVal * fxRate
                        }
                       
                        
                        //console.log(currency)
                        
                       
                    }
                    
                    //add net and gross to month
                    
                    if(isNaN(grossVal) || grossVal === "") {throw `Error: Gross of '${values[grossCol]}' is not a valid number on line ${i+1}`}


                    dataMonth.gross += grossVal
                    dataMonth.net += netVal
                    
                    //calculate vat
                    const vatRate = getRate(i, values[countryCol].trim())
                    const vatVal = netVal * vatRate
                    dataMonth.vat += vatVal

                }

               
            
                

            }
        

        }

         ///calculate averages
         for(const [yearKey, year] of Object.entries(output.monthData)){
             for(const [monthKey, month] of Object.entries(year)){
                 output.avg.gross += month.gross
                 output.avg.net += month.net
                 output.avg.vat += month.vat
             }
         }

         output.avg.gross = output.avg.gross/output.totalMonths
         output.avg.net = output.avg.net/output.totalMonths
         output.avg.vat = output.avg.vat/output.totalMonths
         console.log(output)
        return output

    }catch(e){
        console.error(e)
        return {
            error: true,
            message: e
        }
    }
    
    
}

function getTransactionDate(i, payment, invoice){
    if((!payment.includes("/") || !invoice.includes("/")) && ((payment !== "" && invoice !== ""))){
        throw `Error: Date on line ${i+1} is in incorrect format ensure it is in DD/MM/YYYY`
    }
   
    let transDate = ""

    if(payment !== "" && invoice !== ""){
        let dateParts = payment.split("/")
        const paymentDate = new Date(dateParts[2], dateParts[1]-1, dateParts[0])
        dateParts = invoice.split("/")
        const invoiceDate = new Date(dateParts[2], dateParts[1]-1, dateParts[0])


        if(paymentDate < invoiceDate){
            transDate = paymentDate
        }else{
            transDate = invoiceDate
        }
       
    }else if(payment !== ""){
        let dateParts = payment.split("/")
        transDate = new Date(dateParts[2], dateParts[1]-1, dateParts[0])
    }else if(invoice !== ""){
        let dateParts = invoice.split("/")
        transDate = new Date(dateParts[2], dateParts[1]-1, dateParts[0])
    }else{
        throw  `Error: No date found on row ${i}`
        
    }

    return transDate
}

function getRate(i, country){
    if(!vatRates[country]){
        throw `Error country code '${country}' on line ${i+1} is invalid!`
    }
    
   return  vatRates[country].standard_rate
}