import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Box, Button, Divider,IconButton,List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx'
import LinearProgress from '@material-ui/core/LinearProgress';
import {performCalculation} from '../Logic/calculate';



const useStyles = drawerWidth => makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content:{
        flexGrow: 1,
        overflow: "auto",
        /* width */
        "&::-webkit-scrollbar": {
          width: 10
      },
  
    },

    sidebarSelected: {
        backgroundColor: [[theme.palette.primary.main], "!important"],
        color: "white"
    },
}));

export default function Sidebar(props){
    const {children, drawerWidth, files, setFiles, selected, setSelected, setProcessing} = props
    const classes = useStyles(drawerWidth)();

    
    const [loading, setLoading] = useState(false)

    const fileSelector = document.createElement("input")
    fileSelector.setAttribute("type", "file")
    fileSelector.style.display = "none"
    fileSelector.multiple = true
    fileSelector.accept = [".csv", "text/plain"]

    fileSelector.onchange = e => {
        setLoading(true)
        const newFiles = []
        for (const file of fileSelector.files){
            newFiles.push({
                fileData: file,
                data: {}
            })
        }
        setFiles([...files, ...newFiles])
    }

    useEffect(() => {
        if(loading) setLoading(false)
      }, [files])

    const deleteFile = (index) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1)
        ])
        
        setSelected([
            ...selected.slice(0, index),
            ...selected.slice(index + 1)
        ])
        
    }

    const calculate = async (i) => {

        const newSelected = [...selected];
        newSelected[i] = !newSelected[i]
        setSelected(newSelected)

        
        if(!selected[i]){
            setProcessing(true)
            const setValue = await performCalculation(files[i].fileData)

            setFiles([
                ...files.slice(0, i),
                {...files[i],
                    data: setValue
                },
                ...files.slice(i+1)
            ])
            //console.log(setValue)
            setProcessing(false)
        }
       
        
        
    }
    const FileList = files.map((file, i) => {
       return <ListItem
            button
            key={file.fileData.name}
            onClick={() => calculate(i)}
            selected={selected[i]}
            color="textPrimary"
        >
        <ListItemIcon classes={{root:clsx({ [classes.sidebarSelected]: selected[i] }) }}><DescriptionIcon/></ListItemIcon>
        <ListItemText primary={file.fileData.name} />
        <ListItemSecondaryAction>
            <IconButton onClick={() => deleteFile(i)} classes={{root:clsx({ [classes.sidebarSelected]: selected[i] }) }}>
                <DeleteIcon />
            </IconButton>
            
            </ListItemSecondaryAction>
        </ListItem>

   })
  
 
    return <div>
        <Drawer 
        variant="permanent" 
        anchor="left" 
        className={`${classes.drawer} no-printme`} 
        classes={{
          paper: classes.drawerPaper,
        }}> 
            <Box css={{ height: 100 }} />
            <Divider />
            {loading && <LinearProgress color="primary" />}
            {!loading &&<div className={classes.content}>
                <List>
                    {FileList}
                </List>
            </div>}
            <Divider />
            <Button color="primary" variant="contained" onClick={e => fileSelector.click()}>Upload</Button>
        </Drawer>
        {children}
    </div>
}